<template>
  <div class="mb-3">
    <v-row>

      <v-col cols="3" class="d-flex flex-column">
        <v-card class="shadow rounded-lg flex d-flex flex-column">
          <v-card-text class="pa-6 flex">
            <div class="d-flex align-center justify-space-between">
              <div>
                <v-skeleton-loader v-if="isLoading" type="text" width="50"/>
                <span class="font-weight-medium d-block grey--text text--darken-3 fs-20" v-else>
                  {{ statistics.total_campaigns }}
                </span>
                <p class="mt-2 mb-0">Total Campagnes</p>
              </div>
              <v-spacer/>
              <v-avatar class="bg-grad-primary" size="45">
                <v-icon color="primary" size="25">mdi-file-document-outline</v-icon>
              </v-avatar>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="3" class="d-flex flex-column">
        <v-card class="shadow rounded-lg flex d-flex flex-column">
          <v-card-text class="pa-6 flex">
            <div class="d-flex align-center justify-space-between">
              <div>
                <v-skeleton-loader v-if="isLoading" type="text" width="50"/>
                <span class="font-weight-medium d-block grey--text text--darken-3 fs-20" v-else>
                  {{ statistics.total_sales }}
                </span>
                <p class="mt-2 mb-0">Total Ventes</p>
              </div>
              <v-spacer/>
              <v-avatar class="bg-grad-secondary" size="45">
                <v-icon color="secondary" size="25">mdi-shopping-outline</v-icon>
              </v-avatar>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6" class="d-flex flex-column">
        <v-card class="shadow rounded-lg flex d-flex flex-column">
          <v-card-text class="pa-6 flex">
            <div class="d-flex align-center justify-space-between">
              <div>

                <div class="d-flex align-center">

                  <div>
                    <v-skeleton-loader v-if="isLoading" type="text" width="50"/>

                    <div class="font-weight-medium d-block grey--text text-no-wrap text--darken-3 fs-17" v-else>
                      {{ $func.formatPrice(statistics.total_flexy) }} DZD
                    </div>
                    <div class="text-no-wrap">
                      Total Flexy
                    </div>
                  </div>


                  <v-divider vertical class="mx-8"></v-divider>


                  <div>

                    <v-skeleton-loader v-if="isLoading" type="text" width="50"/>

                    <div class="font-weight-medium text-no-wrap d-block grey--text text--darken-3 fs-17" v-else>
                      {{ $func.formatPrice(statistics.total_gifty) }} DZD
                    </div>

                    <div class="text-no-wrap">
                      Total Gifty
                    </div>
                  </div>


                  <v-divider vertical class="mx-8"></v-divider>

                  <div>
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">

                        <div class="d-flex align-center">
                          <v-chip small label class="bg-primary-subtle font-weight-medium ml-3 primary--text"
                                  v-bind="attrs"
                                  v-on="on">
                            <v-icon left small color="primary">mdi-calendar</v-icon>
                            Filtrer
                            <v-icon right small color="primary">mdi-chevron-down</v-icon>
                          </v-chip>


                          <v-chip label small class="bg-red-subtle font-weight-medium d-flex ml-2 red--text"
                                  v-if="dates.length === 2"
                                  @click="[dates = [],getStatistics()]">
                            <v-icon left small color="red">mdi-close</v-icon>
                            Réinitialiser
                          </v-chip>

                        </div>

                      </template>
                      <v-date-picker range v-model="dates" scrollable color="primary">
                        <v-spacer></v-spacer>
                        <v-btn depressed class="white--text" color="primary" :disabled="(dates.length < 2)"
                               @click="[getStatistics(),menu = false]">
                          Valider
                        </v-btn>
                      </v-date-picker>
                    </v-menu>


                  </div>

                </div>


              </div>
              <v-spacer/>
              <v-avatar color="primary" size="45">
                <GiftyWhiteIcon/>
              </v-avatar>
            </div>
          </v-card-text>
        </v-card>
      </v-col>


    </v-row>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import GiftyWhiteIcon from "@/components/svg-icons/GiftyWhiteIcon";

export default {
  components: {GiftyWhiteIcon},
  data() {
    return {
      menu: false,
      isLoading: false,
      dates: [],
      statistics: {},
    }
  },
  methods: {
    getStatistics() {
      this.isLoading = true
      HTTP.get('/campaigns/statistics', {
        params: {
          start_date: this.dates.length === 2 ? this.dates[0] : null,
          end_date: this.dates.length === 2 ? this.dates[1] : null
        }
      }).then((res) => {
        this.isLoading = false
        this.statistics = res.data.data
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
  },
  created() {
    this.getStatistics()
  }
}
</script>

<style scoped>

</style>